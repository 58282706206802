// App.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import IMG_7884 from './assets/images/IMG_7884.png';
import IMG_7886 from './assets/images/IMG_7886.png';
import IMG_7887 from './assets/images/IMG_7887.png';
import IMG_7888 from './assets/images/IMG_7888.png';
import IMG_7889 from './assets/images/IMG_7889.png';
import IMG_7890 from './assets/images/IMG_7890.png';
import IMG_7891 from './assets/images/IMG_7891.png';
import IMG_7892 from './assets/images/IMG_7892.png';
import IMG_7894 from './assets/images/IMG_7894.png';
import IMG_7895 from './assets/images/IMG_7895.png';

const App = () => {
  useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, []);

  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState(null); // { type: 'success' | 'error', message: '...' }
  const [showButtons, setShowButtons] = useState(false); // State to control button visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await fetch('https://lucidcrypto7.com/submit_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
  
        const data = await response.json();
  
        console.log('Server response:', data); // Log for debugging
  
        if (response.ok) {
          setAlert({ type: 'success', message: 'Thank you! Your email has been submitted.' });
          setEmail(''); // Clear the input field on success
          setShowButtons(true); // Show the buttons on success
        } else if (
          data.details &&
          data.details.ErrorMessage &&
          data.details.ErrorMessage.includes('Email already exists')
        ) {
          // Custom handling for the "email already exists" error
          setAlert({ type: 'success', message: 'Email already provided.' });
          setShowButtons(true); // Show the buttons even if the email was already provided
        } else {
          // General error handling
          setAlert({ type: 'error', message: 'An error occurred while submitting your email.' });
        }
      } catch (error) {
        console.error('Error submitting email:', error);
        setAlert({ type: 'error', message: 'There was an error submitting your email. Please try again.' });
      }
  
      // Automatically dismiss the alert after 5 seconds
      setTimeout(() => {
        setAlert(null);
      }, 5000);
    }
  };
  
  
  

  const closeAlert = () => {
    setAlert(null);
  };

  const [buttonText, setButtonText] = useState('Submit');

  const handleButtonClick = () => {
    setButtonText('Done!');
    setTimeout(() => {
      setButtonText('Submit');
    }, 1000);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const sliderImages = [
    IMG_7884,
    IMG_7886,
    IMG_7887,
    IMG_7888,
    IMG_7889,
    IMG_7890,
    IMG_7891,
    IMG_7892,
    IMG_7894,
    IMG_7895,
  ];

  return (
    <div className="app">
      <div className="container">
        <h1>Lucid Crypto</h1>
        <h2>Enter your email to continue!</h2>

        <form onSubmit={(e) => { handleSubmit(e); handleButtonClick(); }} className="email-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className="button" type="submit">
            {buttonText}
          </button>
        </form>

        {alert && (
          <div className={alert.type === 'success' ? 'success-message' : 'error-message'}>
            <div className="alert__icon">
              {alert.type === 'success' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path fill="#fff" d="M10 15l-3.5-3.5 1.41-1.41L10 12.17l5.09-5.09L16.5 8.5 10 15z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path fill="#fff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10
                  10-4.48 10-10S17.52 2 12 2zm5 13l-1.41 1.41L12 13.41l-3.59
                  3.59L7 15l3.59-3.59L7 7.83 8.41 6.41 12 10l3.59-3.59L17 7.83l-3.59
                  3.59L17 15z" />
                </svg>
              )}
            </div>
            <div className="alert__title">{alert.message}</div>
            <div className="alert__close" onClick={closeAlert}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20">
                <path fill="#fff" d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36
                5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0
                00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707
                10l3.64-3.64a.5.5 0 000-.708z"></path>
              </svg>
            </div>
          </div>
        )}

        {showButtons && (
          <div className="subscription-buttons" style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{color: 'yellow'}}>JOIN THE VIP DISCORD</h1>
            <h2>WHY NOT TRY ONE MONTH?</h2>
            <a href="https://upgrade.chat/cbtradinggroup/p/lucid-monthly" target="_blank" rel="noopener noreferrer">
              <button className="sub-button" style={{ marginBottom: '2vh', paddingBottom: '2vh' }}>Monthly</button>
            </a>
            <h2>ONLY £25/MONTH FOR THE YEAR WHEN YOU OPT IN!</h2>
            <a href="https://upgrade.chat/cbtradinggroup/p/cblucid1y" target="_blank" rel="noopener noreferrer">
              <button className="sub-button" style={{ marginBottom: '2vh', paddingBottom: '2vh'}}>Yearly</button>
            </a>
            <h2>JOIN MY FREE TELEGRAM</h2>
            <a href="https://t.me/+isuzWZ9LOR0zOTM0" target="_blank" rel="noopener noreferrer">
              <button className="sub-button" style={{ marginBottom: '2vh', paddingBottom: '2vh' }}>Telegram</button>
            </a>
          </div>
        )}

        <h2>Customer Testimonials</h2>

        <div className="image-slider">
          <Slider {...sliderSettings} style={{ width: '50%', margin: '0 auto' }}>
            {sliderImages.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default App;
